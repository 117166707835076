<div #carouselHolder>
    <div class="flex" *ngIf="showcitis">
        <span>
            <app-ato-select [selecData]=selecData (itemselected)="chamgevalue($event)"></app-ato-select>
        </span>
    </div>
    <div class="flexunique" *ngIf="!showcitis" >
    </div>
    <div *ngIf="isCarouselFor1stTabVisible" class="wrapper-flex" [ngClass]="showcitis ? 'normal' : 'digital'">
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of emisoraslist;">
                <app-ato-image [ngClass]="'img-cover'" [img]=item [width]=90 [height]=90 (emisoraSelect)="selecEmisora($event)"></app-ato-image>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>