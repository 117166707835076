import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { select } from '../../models/select'
import { EmisoraChangeService } from '../../service/emisora-change.service'
import { Posts } from '../../models/Posts'
import { CityChangeService } from '../../service/city-change.service'
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-mol-emisora',
  templateUrl: './mol-emisora.component.html',
  styleUrls: ['./mol-emisora.component.scss']
})
export class MolEmisoraComponent implements OnInit {
  @ViewChild('carouselHolder', { static: false }) carouselHolder: ElementRef | undefined;
  isCarouselFor1stTabVisible = false;

  @Input()
  selecData!: select;
  @Input()
  emisoraslist!: Posts[];
  @Input()
  showcitis!: boolean ;

  customOptions: OwlOptions = {
    loop: false,
    center: false,
    margin: 0,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,    
    navText: ['<i _ngcontent-c33="" class="icon-angle-right"></i>', '<i _ngcontent-c33="" class="icon-angle-right"></i>'],
    responsive: {
      0: {
        items: 3,
      },
      290: {
        items: 3,
      },
      320: {
        items: 3,
      },
      400: {
        items: 6,
      },
      740: {
        items: 6,
      },
      940: {
        items: 11,
      }
    },
    nav: true
  };


  constructor(
    public emisoraChangeService:EmisoraChangeService,
    public cityChangeService:CityChangeService
    ) { }

  ngOnInit(): void {
  }
  chamgevalue(cityId:Number){
    this.cityChangeService.citychange(cityId)
  }

  ngAfterViewChecked() {
    this.showCarousel();
  }
  showCarousel(): void {
    if (this.carouselHolder) {
      if (this.carouselHolder.nativeElement.clientWidth > 0 && !this.isCarouselFor1stTabVisible) {
        setTimeout(() => {
          this.isCarouselFor1stTabVisible =  true;
        }, 0);
      }
    }

  }
  selecEmisora(emisoraselected:any){
    if(emisoraselected){
      let img = '';
      if (emisoraselected.thumbnail){
        img = emisoraselected.thumbnail
      } else{
        if (emisoraselected.attachments) {
          img = emisoraselected.attachments[emisoraselected.attachments.length -1].url
        }else{
          img =  '../../../assets/img/logo-rcn-mundo.svg';
        }
      }
      this.emisoraChangeService.cangeEmisora(        
        emisoraselected.custom_fields.url_stream[0],
        img,
        emisoraselected.title,
        emisoraselected.custom_fields.station,
        emisoraselected.custom_fields.site,
        emisoraselected.custom_fields.tw,
        emisoraselected.custom_fields.fb,
        emisoraselected.custom_fields.ig)
    }
  }
}
