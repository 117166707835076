import { Injectable,  } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../models/Response'
import {  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private api = environment.api;
  private restful = `${this.api}/api`;

  constructor(private http: HttpClient) { }

  get(endpoint: string):Observable<Response> {
    return this.http.get<Response>(this.restful + '/' + endpoint);
  }

  post(endpoint: string, _send: any) {
    return this.http.post(this.restful + '/' + endpoint, _send);
  }

  getLocal(endpoint: string) {
    return this.http.get(endpoint);
  }

  postPool(endpoint: string, _send: any) {
    return this.http.post(this.restful + '/' + endpoint, _send);
  }
  getCitys(){
    return {
      select:[
        {id:6, name : "Armenia"}, 
        {id:9, name : "Barbosa"}, 
        {id:7, name : "Barrancabermeja"}, 
        {id:8, name : "Barranquilla"}, 
        {id:10, name : "Bello"}, 
        {id:11, name : "Bogotá"}, 
        {id:12, name : "Bucaramanga"}, 
        {id:13, name : "Buenaventura"}, 
        {id:14, name : "Buga"}, 
        {id:15, name : "Cajamarca"}, 
        {id:16, name : "Calarcá"}, 
        {id:17, name : "Cali"}, 
        {id:18, name : "Cartagena"}, 
        {id:19, name : "Cartago"}, 
        {id:20, name : "Caucasia"}, 
        {id:21, name : "Cúcuta"}, 
        {id:22, name : "Dosquebradas"}, 
        {id:23, name : "Duitama"}, 
        {id:24, name : "Espinal"}, 
        {id:25, name : "Flandes"}, 
        {id:26, name : "Fredonia"}, 
        {id:28, name : "Fusagasugá"}, 
        {id:29, name : "Girardot"}, 
        {id:30, name : "Girardota"}, 
        {id:31, name : "Girón"}, 
        {id:33, name : "Guarne"}, 
        {id:34, name : "Ibagué"}, 
        {id:35, name : "Itaguí"}, 
        {id:36, name : "La Ceja"}, 
        {id:38, name : "La Paz"}, 
        {id:39, name : "Lorica"}, 
        {id:41, name : "Manizales"}, 
        {id:42, name : "Medellín"}, 
        {id:43, name : "Montería"}, 
        {id:44, name : "Neiva"}, 
        {id:45, name : "Ocaña"}, 
        {id:46, name : "Pasto"}, 
        {id:47, name : "Pereira"}, 
        {id:48, name : "Piedecuesta"}, 
        {id:49, name : "Planeta Rica"}, 
        {id:50, name : "Popayán"}, 
        {id:51, name : "Riohacha"}, 
        {id:52, name : "Rionegro"}, 
        {id:53, name : "Sabana de Torres"}, 
        {id:54, name : "San Andrés"}, 
        {id:55, name : "San Gil"}, 
        {id:56, name : "Santa Marta"}, 
        {id:57, name : "Santo Tomás"}, 
        {id:58, name : "Sevilla"}, 
        {id:59, name : "Sincelejo"}, 
        {id:60, name : "Sogamoso"}, 
        {id:61, name : "Tuluá"}, 
        {id:62, name : "Tumaco"}, 
        {id:63, name : "Tunja"}, 
        {id:64, name : "Valledupar"}, 
        {id:65, name : "Villa de Leyva"}, 
        {id:66, name : "Villa María"}, 
        {id:67, name : "Villapinzón"}, 
        {id:68, name : "Villavicencio"}
      ],
      selectedValue:11
    }
  }
}
