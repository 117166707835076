import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
@Component({
  selector: 'app-ato-video',
  templateUrl: './ato-video.component.html',
  styleUrls: ['./ato-video.component.sass']
})
export class AtoVideoComponent implements OnInit {
  @ViewChild('videoplay',{ static: false }) videoplay: ElementRef | undefined;

  @Input()
  poster: any ;
  @Input()
  video:any = '';

  constructor() { }
  ngOnInit(): void {

  }
  onEnd(player:any){
    if (player != undefined) {
      player.nativeElement.play()
    }
  }
}
