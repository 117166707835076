import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mol-video',
  templateUrl: './mol-video.component.html',
  styleUrls: ['./mol-video.component.scss']
})
export class MolVideoComponent implements OnInit {
  @Input()
  audio: any ;
  @Input()
  poster: any ;
  @Input()
  name: any ;
  @Input()
  data: any ;

  @Input()
  changedboolean: any;

  @Input()
  isfirt:any;

  constructor() { }

  ngOnInit(): void {
  }

}
