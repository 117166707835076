import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mol-ads',
  templateUrl: './mol-ads.component.html',
  styleUrls: ['./mol-ads.component.sass']
})
export class MolAdsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
