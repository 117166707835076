<div class="wrapper-video">
    <section>
        <app-ato-video [poster]=poster></app-ato-video>
    </section>
    <section *ngIf="audio && changedboolean == true">
        <app-ato-audio [audio]=audio [poster]=poster [name]=name [data]=data [isfirt]=isfirt></app-ato-audio>
    </section>
    <section *ngIf="audio && changedboolean == false">
        <app-ato-audio [audio]=audio [poster]=poster [name]=name [data]=data [isfirt]=isfirt></app-ato-audio>
    </section>
</div>
