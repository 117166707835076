import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-org-audiovisual',
  templateUrl: './org-audiovisual.component.html',
  styleUrls: ['./org-audiovisual.component.scss']
})
export class OrgAudiovisualComponent implements OnInit {
  @Input()
  audio: any ;

  @Input()
  poster: any ;
  
  @Input()
  name: any ;

  @Input()
  data: any ;

  @Input()
  changedboolean:any;

  @Input()
  isfirt:any;
  

  constructor() { }

  ngOnInit(): void {
  }

}
