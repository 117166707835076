import { Component, OnInit, Input } from '@angular/core';
import { select } from '../../models/select'
import { Posts } from '../../models/Posts'
import { Response } from '../../models/Response'
@Component({
  selector: 'app-org-emisoras',
  templateUrl: './org-emisoras.component.html',
  styleUrls: ['./org-emisoras.component.scss']
})
export class OrgEmisoraComponent implements OnInit {
  @Input()
  selecData!: select;
  @Input()
  posts!: Posts[] ;
  @Input()
  showcitis!: boolean ;
  

  constructor() { }

  ngOnInit(): void {

  }

}
