<div class="container">
  <!--pauta?-->
    <div class="adsTop">
    <div id='div-gpt-ad-1661526144630-0'></div>

    </div>
  <!--pauta?-->

    <div class="flex">
        <span class="logo-rcn-mundo">           
        </span>
        <mat-tab-group [ngClass]="'tabs-custom'" mat-align-tabs="start">
            <mat-tab label="Sistemas de radio">
                <span *ngIf="reponse_by_city">
                    <app-org-emisoras [selecData]=emisoraObj [posts]=posts [showcitis]="true"></app-org-emisoras>
                </span>
            </mat-tab>
            <mat-tab label="Emisoras Digitales" style="overflow-x:hidden">
                <span *ngIf="reponse_by_city">
                    <app-org-emisoras [selecData]=emisoraObj [posts]=postsvirtual [showcitis]="false" ></app-org-emisoras>
                </span>
            </mat-tab>
        </mat-tab-group>
        <section class="player-video">
            <app-org-audiovisual [ngClass]="' '" [audio]=audio [poster]=poster [name]=emisoraName [data]=data [changedboolean]=changedboolean [isfirt]="isfirt"></app-org-audiovisual>
        </section>
    </div>
    <div class="flex">
        <a target="_blank"  class="wraper-download" href="https://bit.ly/rcnmundoapp">
            <p>Descarga la aplicación:</p>
            <div class="flex">
                <section class="imgsectionhome">
                    <img alt="logo appstore" class="apstore" src="/assets/img/appstore.webp"/>
                </section>
                <section  class="imgsectionhome">
                    <img alt="logo google play" class="googleplay" src="/assets/img/googleplay.webp"/>
                </section>

            </div>
        </a>

        

    </div>
    <div class="adsBottom">
        <div id='div-gpt-ad-1661526281162-0'>
        </div>
    </div>


</div>
<div class="footer-wrapper">
    <!-- Footer -->
    <h2 class="flex-row">
        <img alt="Logo rcn radio" class="googleplay" src="/assets/img/logo-rcn-radio.svg"/>  Red de Portales
    </h2>
    <div class="flex-foot">
        <ul>
            <li><a target="_blank" href="https://www.rcnmundo.com">RCN Mundo</a></li>
            <li><a target="_blank" href="https://www.rcnradio.com/">RCN Radio</a></li>
            <li><a target="_blank" href="https://www.lamega.com.co/">La Mega</a></li>
            <li><a target="_blank" href="https://www.elsol.com.co/">El Sol</a></li>
            <li><a target="_blank" href="https://www.radio1.com.co">Radio 1</a></li>
            <li><a target="_blank" href="https://www.fiesta.com.co">Fiesta</a></li>
        </ul>
        <ul>
            <li><a target="_blank" href="https://www.lafm.com.co/">La F.M</a></li>
            <li><a target="_blank" href="https://www.antena2.com/">Antena 2</a></li>
            <li><a target="_blank" href="https://www.lacariñosa.com/">La Cariñosa</a></li>
            <li><a target="_blank" href="https://www.fantastica.com.co/">Fantástica</a></li>
            <li><a target="_blank" href="https://www.rumba.com.co/">Rumba</a></li>
            <li><a target="_blank" href="https://www.radiored.com.co/">Radio Red</a></li>
        </ul>
    </div>
    <div class="copy-foot">
        <ul class="copyright">
            <li>
                <a target="_blank" href="https://www.rcnmundo.com/atencionaloyente/">Atención al Oyente</a>
            </li>
            <li>
                <a target="_blank" href="https://www.rcnradio.com/legal/aviso-de-privacidad">Términos y condiciones</a>
            </li>
            <li>
                <a target="_blank" href="https://www.rcnradio.com/legal/politica-de-proteccion-de-datos-personales">Política de privacidad</a>
            </li>
        </ul>
        <h3 class="copy">Copyright RCN Radio © 2022</h3>
    </div>
</div>