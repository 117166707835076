import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ChangeurlbackService } from '../service/changeurlback.service'
@Injectable({
  providedIn: 'root'
})
export class AdsHeaderBiddingService {

  constructor(
    @Inject(DOCUMENT) private doc:any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private changeurlbackService:ChangeurlbackService) { }

  insertScriptHead(headerBidding: any) {
    if (isPlatformBrowser(this.platformId)){
      const scriptExist = this.doc.querySelector('script[data-hbid=' + headerBidding.id + ']');
  
      if (scriptExist) {
        scriptExist.text = JSON.stringify(headerBidding.script);
      } else {
  
          const scriptHB2: HTMLScriptElement = this.doc.createElement('script');
          scriptHB2.type = 'text/javascript';
          scriptHB2.setAttribute('data-hbid', headerBidding.id);
          scriptHB2.text = headerBidding.script;
          this.doc.head.appendChild(scriptHB2);
      }
    }
  }
  insertScriptHeadMetatags(name: any, content: any, item:any) {
    const scriptExist = this.doc.querySelector(item+"[rel='" + name + "']");

    if (scriptExist) {
      scriptExist.text = content;
    } else{
      const scriptHB2: HTMLScriptElement = this.doc.createElement(item);
      scriptHB2.setAttribute('rel', name);
      scriptHB2.setAttribute('href', content);
      this.doc.head.appendChild(scriptHB2);
    }



  }

  insertScriptHeadMetatagsCustom(name: any, content: any, item:any, property:boolean) {
    const scriptHB2: HTMLScriptElement = this.doc.createElement(item);
    scriptHB2.setAttribute((property) ?  'property': 'name', name);
    scriptHB2.setAttribute('content', content);
    this.doc.head.appendChild(scriptHB2);
  }

  editHead(queryselector: any, text:String) {
      const scriptExist = this.doc.querySelector(queryselector);
      if (scriptExist) {
        scriptExist.innerText = text
      }
  }
  
  insertScriptHeadLdJson(headerBidding: any, number:string) {

    const scriptExist = this.doc.querySelector("script[hbidbody='" + number + "']");
      if (!scriptExist) {
        const scriptHB2: HTMLScriptElement = this.doc.createElement('script');
        scriptHB2.setAttribute('hbidbody', number);
        scriptHB2.setAttribute('type', 'application/ld+json');

        scriptHB2.text = JSON.stringify(headerBidding);
        this.doc.head.appendChild(scriptHB2);

      }
    }

  editAndUpdate(Object:any, canonicalUrl:any,poster:any){
    poster = this.changeurlbackService.urlSSolvedSimple(poster)
    let generalsloga = "RCN MUNDO Radio y Podcast - Emisoras de Colombia en vivo";
    let nameDial: string;
    if (Object.post.custom_fields.station !== undefined) {
      nameDial = Object.post.title + ' ' + Object.post.custom_fields.station[0]+' - '+generalsloga;
    }else{
      nameDial = Object.post.title +' - '+generalsloga;
    }

    let ArrayMeta = [
      // General
      {name:'language',content:'spanish',property:false},
      {name:'author',content:'RCN Radio',property:false},
      {name:'copyright',content:'RCN Radio',property:false},
      {name:'genre',content:'Radio',property:true},
      {name:'geo.region',content:'CO-DC',property:false},    
      {name:"geo.placename", content:"Bogot&aacute;",property:true},
      {name:"geo.position", content:"4.625093;-74.068649",property:true},
      {name:"ICBM", content:"4.625093, -74.068649",property:true},      
      // Article
      {name:"article:section", content:"Radio",property:true},      
      {name:"article:author", content:"RCN Radio",property:true},      
      {name:"article:published_time", content:"2022-08-06T00:00:00.805Z",property:true},      
      {name:"article:modified_time", content:"2022-08-06T00:00:00.183Z",property:true},      
      // Facebook
      {name:"fb:admins", content:"167767896594991",property:true},      
      {name:"fb:pages", content:"167767896594991",property:true},      
      {name:"fb:app_id", content:"437128813159994",property:true},      
      // General
      {name:"title", content:nameDial,property:false},
      {name:"url", content:canonicalUrl,property:false},
      {name:"url", content:canonicalUrl,property:true},
      // Og
      {name:"og:title", content:nameDial,property:true},
      {name:"og:site_name", content:generalsloga,property:true},
      {name:"og:description", content:generalsloga,property:true},
      {name:"og:url", content:generalsloga,property:true},
      {name:"og:type", content:"article",property:true},
      {name:"og:locale", content:"es_LA",property:true},
      {name:"og:image:width", content:"700",property:true},
      {name:"og:image:height", content:"700",property:true},
      {name:"og:image", content:poster,property:true},
      {name:"og:image:secure_url", content:poster,property:true},
      // Twitter
      {name:"twitter:site", content:"@rcnmundo",property:true},
      {name:"twitter:creator", content:"@rcnmundo",property:true},      
      {name:"twitter:title", content:nameDial,property:true},
      {name:"twitter:card", content:'summary_large_image',property:true},
      {name:"twitter:description", content:generalsloga,property:true},
      {name:"twitter:image", content:poster,property:true},      
      
    ]



    ArrayMeta.forEach(element => {
      this.insertScriptHeadMetatagsCustom(element.name,element.content,'meta',element.property)
    });
    this.editHead('title',nameDial);
  }
}
