import { Component, OnInit, Input, Output, EventEmitter,ElementRef, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import videojs from 'video.js'
import { ChangeurlbackService } from '../../service/changeurlback.service'
@Component({
  selector: 'app-ato-audio',
  templateUrl: './ato-audio.component.html',
  styleUrls: [
  './ato-audio.component.scss',
  '../../../../node_modules/video.js/src/css/video-js.scss']
})
export class AtoAudioComponent implements OnInit {
  @ViewChild('audioplayer',{ static: false }) audioPlayer: ElementRef | undefined;
  @ViewChild('sourcevideo',{ static: false }) sourcevideo: ElementRef | undefined;

  @Input()
  audio: any;
  
  @Input()
  poster: any ;
  
  @Input()
  name: any ;

  @Input()
  data: any;

  @Input()
  isfirt:any;

  player:any;
  options:any;
  type:any = "video/mp4";

  constructor(    
    @Inject(DOCUMENT) private doc:any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private changeurlbackService:ChangeurlbackService
    ) { 
      player: videojs.Player
    }


  ngOnInit(): void {
    const urlplayer = 'https://player.cdn.mdstrm.com/lightning_player/api.js';
    const scriptExist = this.doc.querySelector('script[src="' + urlplayer + '"]');

    if (scriptExist) {
      scriptExist.remove()
      const scriptHB3: HTMLScriptElement = this.doc.createElement('script');
      scriptHB3.setAttribute('type', 'text/javascript');
      scriptHB3.setAttribute('src', urlplayer);    
      this.doc.head.appendChild(scriptHB3);
    }else{
      const scriptHB3: HTMLScriptElement = this.doc.createElement('script');
      scriptHB3.setAttribute('type', 'text/javascript');
      scriptHB3.setAttribute('src', urlplayer);    
      this.doc.head.appendChild(scriptHB3);
    }

  }

  loadVidejs(){
    this.type = (this.audio.includes('.m3u8')) ? "application/x-mpegURL" : "video/mp4" ;
    let option:any = {
      sources: {
        src: this.audio,
        type:this.type,
    },
    }
    if (this.audioPlayer != undefined) {
      this.player = videojs(this.audioPlayer.nativeElement, option, function onPlayerReady() {
      });
    }else{
      setTimeout(() => {
        this.loadVidejs()
      }, 0);
    }
  }

  ngOnChanges(changes: any) {
    if (changes.audio) {
      if (changes.audio.currentValue != undefined){
        if (isPlatformBrowser(this.platformId)) {
          this.loadSound(this.isfirt)
        }
      }
    }

  }
  loadSound(isfirt:any){
    setTimeout(()=>{
      this.playSound(this.audioPlayer,isfirt)
    },10)
  }

  pushPlayerAudio(){
    (<any>window).dataLayer.push({'emisora':this.name ,'event': 'player_audio'});
  }
  playSound(player:any,isfirt:any){
    if (player != undefined && !isfirt) {
      this.pushPlayerAudio()
    }
  }
  stopSound(player:any){
    //player.nativeElement.pause()
  }
  algo(event:any){
    if (this.isfirt){
      this.pushPlayerAudio()
    }
  }
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.audioPlayer != undefined) {
      this.audioPlayer.nativeElement.remove();
    }
  }
  urlsSolved(data:any){
    return this.changeurlbackService.urlSSolvedSimple(data)
   }

}

