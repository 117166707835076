import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component'
import { OrganismsModule } from '../organisms/organisms.module'
import { MatTabsModule } from '@angular/material/tabs'; 



@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    OrganismsModule,
    MatTabsModule
  ]
})
export class PagesModule { }
