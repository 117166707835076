import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MolVideoComponent } from './mol-video/mol-video.component';
import { MolEmisoraComponent } from './mol-emisora/mol-emisora.component';
import { AtomsModule } from '../atoms/atoms.module';
import { MolAdsComponent } from './mol-ads/mol-ads.component';
import { MolCarruselComponent } from './mol-carrusel/mol-carrusel.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';

const molComponents = [
  MolVideoComponent,
  MolEmisoraComponent,
  MolAdsComponent,
  MolCarruselComponent,
]

@NgModule({
  declarations: [...molComponents ],
  imports: [
    CommonModule,
    AtomsModule,
    CarouselModule,
    BrowserAnimationsModule,
    RouterModule
  ],  
  exports: [...molComponents]
})
export class MoleculesModule { }
