import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtoEmisoraComponent } from './ato-emisora/ato-emisora.component';
import { AtoVideoComponent } from './ato-video/ato-video.component';
import { AtoImageComponent } from './ato-image/ato-image.component';
import { AtoParagrafComponent } from './ato-paragraf/ato-paragraf.component';
import { AtoAudioComponent } from './ato-audio/ato-audio.component';
import { AtoAdsComponent } from './ato-ads/ato-ads.component';
import { AtoSelectComponent } from './ato-select/ato-select.component';
import { MatSelectModule } from '@angular/material/select'; 
import { NohttpsPipe } from '../pipes/nohttps.pipe';

const atomComponents = [
  AtoEmisoraComponent,
  AtoEmisoraComponent,
  AtoImageComponent, 
  AtoParagrafComponent,
  AtoVideoComponent,
  AtoAudioComponent,
  AtoAdsComponent,
  AtoSelectComponent,
  NohttpsPipe
]

@NgModule({
  declarations: [...atomComponents],
  imports: [
    CommonModule,
    MatSelectModule
  ],
  exports: [...atomComponents]
})
export class AtomsModule { }
