import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmisoraChangeService {

  constructor() { }

  $subjetct = new Subject();

  cangeEmisora(
    emisora:any,
    img:any,
    name:any,
    station:any,
    site:any,
    twitter: any,
    facebook:any,
    instagram:any
    ):void{

    let redes = [
      {link:twitter,
      name:'twitter'}, 
      {link:facebook,
      name:'facebook'},
      {link:instagram,
        name:'instagram'}]
    this.$subjetct.next({
      emisora,
      img,
      name,
      station,
      site,
      redes
    })
  }
}