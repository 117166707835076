import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class ChangeurlbackService {

  constructor() { }

  urlsSolved(data:any){
    if (data) {
      let img = '';

      if (data.thumbnail){
        img = data.thumbnail
      } else{
        if (data.attachments) {
          if (data.attachments[0]) {
            img = data.attachments[data.attachments.length -1].url            
          }else{
          img =  '../../../assets/img/logo-rcn-mundo.svg';
        }

        }else{
          img =  '../../../assets/img/logo-rcn-mundo.svg';
        }

      }
        if (img.includes(environment.canonical)){
          return img.replace(environment.canonical,environment.api )
        }
        return img
      }else{
        return '../../../assets/img/logo-rcn-mundo.svg';
      }
    }
  urlSSolvedSimple(data:any){
    if (data) {
      if (data.includes(environment.canonical)){
        return data.replace(environment.canonical,environment.api)
      }
      return data
    }else{
      return '../../../assets/img/logo-rcn-mundo.svg';
    }
  }
}
