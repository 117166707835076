import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CityChangeService {

  constructor() { }

  $subjetct = new Subject();

  citychange(emisora:any):void{
    this.$subjetct.next(emisora)
  }
}