import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Response } from '../../models/Response';
import { Posts } from '../../models/Posts';
import { EmisoraChangeService } from '../../service/emisora-change.service';
import { CityChangeService } from '../../service/city-change.service';
import { HttpService } from '../../service/http.service'
import { AdsHeaderBiddingService } from '../../service/ads-header-bidding.service'
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  emisoraObj:any = {
    select:[
    ],
    selectedValue:11
  }
  posts!:Posts[]
  postsvirtual!:Posts[]
  reponse_by_city!: Response;
  response_virtual!:Response
  arrayShort:any[] = [];
  audio = ""
  data = {};
  isfirt:boolean = true;
  poster:any = 'https://wordpress.rcnmundo.com/wp-content/uploads/2015/09/racnradio.webp'
  emisoraName:any = ""
  
  canonical:any=environment.canonical;
  slug:any  = 'rcnradio'

  changedboolean:boolean = true

  constructor(
    private emisoraChangeService:EmisoraChangeService,
    private cityChangeService:CityChangeService,
    private httpService:HttpService,
    @Inject(DOCUMENT) private doc: any,
    private adsHeaderBiddingService:AdsHeaderBiddingService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) {

      this.httpService.get('get_category_index').subscribe((response:any)=> {
        this.emisoraObj.select = this.emisoraFilter(response.categories) 
      })

     }

  emisoraChangeHome(change:any){
    this.audio = change.emisora
    this.poster = change.img
    this.emisoraName = change.name
    this.data = change
    this.changedboolean = !this.changedboolean
    this.isfirt = false

  }

  cityChangeHome(value:Number){
    this.emisoraObj.selectedValue = value
    this.getListbyCity(this.emisoraObj.selectedValue)
  }

  getListbyCity(cityID:Number){
    this.httpService.get('get_category_posts/?category_id='+cityID).subscribe((reponse)=> {
      this.reponse_by_city = reponse
      this.posts = this.reponse_by_city.posts
      this.setEstructureDate()
    })
  }

  getListbyVirtual(){
    this.httpService.get('get_category_posts/?category_id=4').subscribe((reponse)=> {
      this.response_virtual = reponse
      this.postsvirtual = this.response_virtual.posts

    })
  }


  ngOnInit(): void {
    this.route.paramMap.subscribe((params:any) => {
      if (params.params.id) {
        this.slug = params.params.id
      }
    });

    let canonicalUrl = this.canonical+ ((this.slug) ? '/'+this.slug : '') 

    this.adsHeaderBiddingService.insertScriptHeadMetatags('canonical',canonicalUrl,'link')

    this.getListbyVirtual()
    this.httpService.get('get_post/?slug='+this.slug).subscribe((response:any)=> {
      this.adsHeaderBiddingService.editAndUpdate(response,canonicalUrl,this.poster)
      let cotyselect:any =  this.emisoraFilter(response.post.categories)
      this.cityChangeHome(cotyselect[0].id)
    })

    this.emisoraChangeService.$subjetct.subscribe((emisochange:any)=>{
      this.emisoraChangeHome(emisochange)
    })

    this.cityChangeService.$subjetct.subscribe((city:any)=>{
      this.cityChangeHome(city)
    })
    if (isPlatformBrowser(this.platformId)) {
      this.loadAds(window.innerWidth);
    }


  }

  loadAds(width:any):void{
    let pautazise = "[[320,100],[300,50],[320,50],[300,100]]";
    if (width >= 728 ) {
      pautazise = "[[728,90],[468,60],[970,90],[990,90],[1030,90]]";
    }
    const scriptHB3: HTMLScriptElement = this.doc.createElement('script');
    scriptHB3.textContent = "window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/1656666/RCNMUNDO/HOME/rcnmundo_home_barraexpander_dm', " +pautazise+ ", 'div-gpt-ad-1661526144630-0').addService(googletag.pubads());    googletag.defineSlot('/1656666/RCNMUNDO/HOME/rcnmundo_home_footerbanner_970x90_d', "+ pautazise+", 'div-gpt-ad-1661526281162-0').addService(googletag.pubads());  googletag.pubads().enableSingleRequest();  googletag.pubads().collapseEmptyDivs();  googletag.pubads().enableSyncRendering();      googletag.enableServices();});";
    this.doc.head.appendChild(scriptHB3);
  
    const scriptHB4: HTMLScriptElement = this.doc.createElement('script');
    scriptHB4.textContent = `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1661526144630-0'); }); 
    googletag.cmd.push(function() { googletag.display('div-gpt-ad-1661526281162-0'); });`;
    this.doc.head.appendChild(scriptHB4);
  }

  emisoraFilter(array:[]){
    return array.filter((arr:any) => {
      return arr.slug !== 'emisora';
    })
  }

  setEstructureDate () {

    let arrayFirst = [
      'LA FM',
      'RCN RADIO',
      'LA MEGA',
      'ANTENA 2'
    ]

    this.posts.forEach((element:any) => {
      if (!arrayFirst.includes(element.title)) {
        this.arrayShort.push(element.title)
      }
      if (element.slug == this.slug) { 
        let img = '';
        if (element.thumbnail){
          img = element.thumbnail
        } else{
          if (element.attachments) {
            img = element.attachments[element.attachments.length -1].url
          }else{
            img =  '../../../assets/img/logo-rcn-mundo.svg';
          }
        }
        let redes = [
          {link:element.custom_fields.tw,
          name:'twitter'}, 
          {link:element.custom_fields.fb,
          name:'facebook'},
          {link:element.custom_fields.ig,
            name:'instagram'}]
        this.emisoraChangeHome({
          emisora:element.custom_fields.url_stream[0],
          img,
          name:element.title,
          station:element.custom_fields.station,
          site:element.custom_fields.site,
          redes})
      }
    });

    let con = arrayFirst.concat(this.arrayShort)
    let finalArray = []
    for (let index = 0; index < con.length; index++) {
      const element = con[index];
      finalArray.push(
        {
        "@id": this.canonical+"/#breadcrumb",
        "@type": "ListItem",
        "position": index+1,
        "name": con[index]
        }
      )    
    }
    const firstStructure = {
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "RCN Mundo",
      "url": this.canonical,
      "logo": this.canonical+"/logo-rcn-mundo.svg",
      "sameAs": [
          "https://twitter.com/rcnradio",
          "https://www.facebook.com/rcnradio",
          "https://www.instagram.com/rcnradiocolombia",
      ]
    }
    this.adsHeaderBiddingService.insertScriptHeadLdJson(firstStructure,'1')

    const secondStructure = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebSite",
          "@id": this.canonical+"/#website", //Todas las url deben ser relativas 
          "url": this.canonical,
          "name": "RCN MUNDO",
          "description": "Radio de Colombia - Emisoras de RCN Radio",
          "inLanguage": "es"
        },
        {
          "@type": "CollectionPage",
          "@id": this.canonical+"/#webpage",
          "url": this.canonical,
          "name": "RCN MUNDO - Radio de Colombia - Emisoras de RCN Radio",
          "isPartOf": {
            "@id": this.canonical+"/#website"
          },
          "description": "Radio de Colombia - Emisoras de RCN Radio",
          "breadcrumb": {
            "@id": this.canonical+"/#breadcrumb"
          },
          "inLanguage": "es",
          "potentialAction": [
            {
              "@id": this.canonical+"/#breadcrumb",
              "@type": "ReadAction",
              "target": [
                this.canonical
              ]
            }
          ]
        },
        {
          "@type": "BreadcrumbList",
          "@id": this.canonical+"/#breadcrumb",
          "itemListElement": finalArray
        }
      ]
    }
    this.adsHeaderBiddingService.insertScriptHeadLdJson(secondStructure,'2')
  }
}
