import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Posts } from '../../models/Posts'
import { ChangeurlbackService } from '../../service/changeurlback.service'
@Component({
  selector: 'app-ato-image',
  templateUrl: './ato-image.component.html',
  styleUrls: ['./ato-image.component.scss']
})
export class AtoImageComponent implements OnInit {

  @Input()
  img!: Posts;

  @Input()
  width!: number | 150;

  @Input()
  height!: number | 150;

  googleTag:any;

  @Output() emisoraSelect = new EventEmitter<any>();
  constructor(private changeurlbackService:ChangeurlbackService) { }

  ngOnInit(): void {

  }
  emisoraSelected(emisora:Posts){
    (<any>window).dataLayer.push({'emisora':emisora.title ,'event': 'cambiaEmisora'});
    this.emisoraSelect.emit(emisora);
  }
  urlsSolved(data:any){
   return this.changeurlbackService.urlsSolved(data)
  }
}
