import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nohttps'
})
export class NohttpsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let https = 'https://'
    let httpswww = 'https://www.'
    let http = 'http://'
    let httpwww = 'http://www.'
    

    if (value.includes(httpswww)){
      return value.replace(httpswww,'')
    }
    else if(value.includes(https)) {
      return value.replace(https,'')
    }
    else if(value.includes(httpwww)) {
      return value.replace(httpwww,'')
    }
    else if(value.includes(http)) {
      return value.replace(http,'')
    }
    else{
      return value
    }
  }

}
