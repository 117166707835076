
<section class="generalSection">

  <div *ngIf="data" class="aftervideo">
    <div class="imglogo">
      <img alt="{{data.name}}" src="{{urlsSolved(poster)}}" width="120" height="120" loading="lazy"/>
    </div>
    <div class="textdescription">
      <p *ngIf="data.name" class="descriptionptitle">
        {{data.name}}
      </p>
      <p *ngIf="data.station" class="descriptionp">
        {{data.station[0]}}
      </p>
      <p *ngIf="data.site" >
        <a href="{{data.site[0]}}" class="descriptionp link"  target="_blank" >
          {{data.site[0] | slice:0:-1 | nohttps}} 
          <i class="fa-solid fa-up-right-from-square"></i>
        </a>
      </p>
      <hr class="hrline descriptionp ">
      <section class="redesdiv descriptionp">
        <p class="redes">Redes: </p>
        <p *ngFor="let redes of data.redes" class="redes">
          <a  *ngIf="redes.name == 'twitter'" href="https://twitter.com/{{redes.link}}" target="_blank" aria-label="twitter"> <i class="fa-brands fa-twitter"></i></a>
          <a *ngIf="redes.name == 'facebook'" href="https://facebook.com/{{redes.link}}" target="_blank" aria-label="facebook"> <i class="fa-brands fa-facebook-f"></i></a>
          <a *ngIf="redes.name == 'instagram'" href="https://www.instagram.com//{{redes.link}}" target="_blank" aria-label="instagram"> <i class="fa-brands fa-instagram"></i></a>
        </p>  
      </section>

    </div>
    
    
  </div>
  
  <section *ngIf="audio">
    <div data-msp data-type='live' 
    attr.data-id="{{audio}}"  data-player='63adceb5c353d960e24e345a'>
    </div>
  </section>
</section>