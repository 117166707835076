import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { Emisora } from '../../models/Emisora'
import { select } from '../../models/select'

@Component({
  selector: 'app-ato-select',
  templateUrl: './ato-select.component.html',
  styleUrls: ['./ato-select.component.scss']
})
export class AtoSelectComponent implements OnInit {
  @Input()
  selecData!: select;
  @Output() itemselected = new EventEmitter<any>();

  constructor() { }
  ngOnInit(): void {
  }

  itemselec(value: any) {

    this.itemselected.emit(value.value);
  }

}
