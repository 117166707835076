import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgCarruselComponent } from './org-carrusel/org-carrusel.component';
import { OrgEmisoraComponent } from './org-emisoras/org-emisoras.component';
import { MoleculesModule } from '../molecules/molecules.module';
import { OrgAudiovisualComponent } from './org-audiovisual/org-audiovisual.component'

const orgComponents = [
  OrgCarruselComponent,
  OrgEmisoraComponent,
  OrgAudiovisualComponent
]

@NgModule({
  declarations: [...orgComponents],
  imports: [
    CommonModule,
    MoleculesModule
  ],
  exports: [...orgComponents]
})
export class OrganismsModule { }
